<template>
    <div class="EvaluationCard">
        <div class="title">
            <div class="flex-ale">
                <div class="name">
                    {{ test.title }}
                </div>
            </div>
            <div class="status" :class="{ green: status != 2 }" v-if="myTest">
                {{ status == 2 ? "已完成" : "进行中" }}
            </div>
        </div>
        <el-divider></el-divider>
        <div class="flex">
            <div class="time">限时{{ time_limit }}分钟</div>
            <div class="time" v-if="myTest">用时{{ use_time }}</div>
        </div>
        <div class="flex-ale between">
            <div class="condition one-line" v-if="!myTest">
                {{ test.remark }}
            </div>
            <div class="flex-ale" v-if="myTest">
                <div class="text">测评结果：</div>
                <div
                    class="result"
                    :class="{
                        red: score < test.qualified && status == 2,
                        green: status == 1,
                    }"
                >
                    {{ status == 2 ? score + "分" : "进行中" }}
                    {{
                        status == 2
                            ? score >= test.qualified
                                ? "及格"
                                : "不及格"
                            : ""
                    }}
                </div>
                <div v-if="myTest" class="number">
                    剩余测评次数：{{ test_num }}
                </div>
            </div>
            <div
                class="test click"
                :class="{
                    'back-gray': !myTest && test.user_eval_status == 2,
                    'back-green': myTest && status == 1,
                }"
                @click="
                    !myTest && test.user_eval_status == 2
                        ? ''
                        : goTest(test_num)
                "
            >
                {{
                    myTest
                        ? status == 2
                            ? "重新测评 >>"
                            : "继续测评 >>"
                        : test.user_eval_status == 2
                        ? "已完成"
                        : "去测评 >>"
                }}
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "EvaluationCard",
        props: {
            myTest: {
                type: Number,
                default: 0,
            },
            test: {
                type: Object,
                default: () => {
                    return {
                        time_limit: 0,
                        time_spent: 0,
                        title: "",
                        qualified: 0,
                        score: 0,
                        user_eval_status: 0,
                        ifanalysis: 1,
                    };
                },
            },
            test_num: {
                type: Number,
                default: 0,
            }, //剩余次数
            score: {
                type: Number,
                default: 0,
            },
            status: {
                status: Number,
                default: 2,
            }, //测评是否完成 未完成则继续
        },
        computed: {
            time_limit: function () {
                let time_limit = this.test.time_limit || 0;
                let minute = (time_limit / 60).toString().split(".")[0];
                let second = time_limit % 60 || 0;
                if (minute < 10) {
                    minute = `0${minute}`;
                }
                if (second < 10) {
                    second = `0${second}`;
                }
                return `${minute}:${second}`;
            },

            use_time: function () {
                let time_spent = this.test.time_spent;
                return time_spent;
            },
        },
        methods: {
            goTest(num) {
                let ifretest = this.myTest ? 1 : 0;
                this.$https
                    .get("/api/exam/evaluation/detail", {
                        id: this.test.id,
                        ifretest: 0,
                    })
                    .then((res) => {
                        if (res.errcode) return this.$message.error(res.msg);
                        if (!ifretest) {
                            if (res.data.status == 2) {
                                this.$router.replace({
                                    path: "/testResult",
                                    query: {
                                        score: res.data.score, //成绩
                                        qualified: res.data.evaluation.qualified, //及格分
                                        paperScore: res.data.evaluation.score, //总分
                                        test: res.data.evaluation.id, //测评id
                                        paper: res.data.id, //试卷id
                                        ifanalysis: this.test.ifanalysis,
                                    },
                                });
                                // return this.$message.error("您已完成该测评！");
                            } else {
                                this.$router.push({
                                    path: "/InTest",
                                    query: {
                                        test: this.test.id,
                                        ifretest,
                                        ifanalysis: this.test.ifanalysis,
                                    },
                                });
                            }
                        } else {
                            if (res.data.status == 1) {
                                return this.$router.push({
                                    path: "/InTest",
                                    query: {
                                        test: this.test.id,
                                        ifretest: 0,
                                        ifanalysis: this.test.ifanalysis,
                                    },
                                });
                            }
                            if (!res.data.test_has_next)
                                return this.$message.error(
                                    "该测评次数已达到上限！"
                                );
                            this.$confirm(
                                `开始测评将使用掉测评次数，是否开始测评？`,
                                "确认信息",
                                {
                                    distinguishCancelAndClose: true,
                                    confirmButtonText: "是",
                                    cancelButtonText: "否",
                                }
                            )
                                .then(() => {
                                    this.$router.push({
                                        path: "/InTest",
                                        query: {
                                            test: this.test.id,
                                            ifretest,
                                            ifanalysis: this.test.ifanalysis,
                                        },
                                    });
                                })
                                .catch((action) => {
                                    return false;
                                });
                        }
                    });
            },
        },
    };
</script>

<style scoped lang='less'>
.EvaluationCard {
    width: 563px;
    background: #ffffff;
    border-radius: 15px;
    padding: 15px 17px;
    margin-bottom: 17px;
    .el-divider--horizontal {
        margin-top: 11px;
        margin-bottom: 8px;
    }
    .title {
        display: flex;
        justify-content: space-between;
        .name {
            font-size: 16px;
            font-weight: 500;
            color: #000000;
        }
        .tab {
            font-size: 10px;
            font-weight: 400;
            color: #82b257;
            background: #ffffff;
            border: 1px solid #a0cc61;
            border-radius: 5px;
            padding: 1px 4px;
            margin-left: 8px;
        }
        .status {
            font-size: 12px;
            font-weight: 400;
            color: #e41414;
        }
        .green {
            color: #a0cc61;
        }
    }
    .time {
        font-size: 12px;
        font-weight: 300;
        color: #000000;
        margin-right: 10px;
    }
    .condition {
        width: 420px;
        -webkit-line-clamp: 2;
        font-size: 12px;
        font-weight: 300;
        color: #000000;
    }
    .text {
        font-size: 12px;
        font-weight: 400;
        color: #000000;
    }
    .result {
        font-size: 16px;
        font-weight: 600;
        color: #a7d06d;
    }
    .number {
        margin-left: 15px;
        font-size: 12px;
        font-weight: 400;
        color: #000000;
    }
    .red {
        color: #ec2c06;
    }
    .green {
        color: #a0cc61;
    }
    .test {
        font-size: 12px;
        font-weight: 400;
        color: #ffffff;
        background: linear-gradient(180deg, #ff3764, #ff2604);
        border-radius: 17px;
        padding: 8px 0px;
        width: 100px;
        text-align: center;
    }
    .back-gray {
        background: #b9b9b9;
    }
    .back-green {
        background: #a0cc61;
    }
}
</style>