var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"EvaluationCard"},[_c('div',{staticClass:"title"},[_c('div',{staticClass:"flex-ale"},[_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(_vm.test.title)+" ")])]),(_vm.myTest)?_c('div',{staticClass:"status",class:{ green: _vm.status != 2 }},[_vm._v(" "+_vm._s(_vm.status == 2 ? "已完成" : "进行中")+" ")]):_vm._e()]),_c('el-divider'),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"time"},[_vm._v("限时"+_vm._s(_vm.time_limit)+"分钟")]),(_vm.myTest)?_c('div',{staticClass:"time"},[_vm._v("用时"+_vm._s(_vm.use_time))]):_vm._e()]),_c('div',{staticClass:"flex-ale between"},[(!_vm.myTest)?_c('div',{staticClass:"condition one-line"},[_vm._v(" "+_vm._s(_vm.test.remark)+" ")]):_vm._e(),(_vm.myTest)?_c('div',{staticClass:"flex-ale"},[_c('div',{staticClass:"text"},[_vm._v("测评结果：")]),_c('div',{staticClass:"result",class:{
                    red: _vm.score < _vm.test.qualified && _vm.status == 2,
                    green: _vm.status == 1,
                }},[_vm._v(" "+_vm._s(_vm.status == 2 ? _vm.score + "分" : "进行中")+" "+_vm._s(_vm.status == 2 ? _vm.score >= _vm.test.qualified ? "及格" : "不及格" : "")+" ")]),(_vm.myTest)?_c('div',{staticClass:"number"},[_vm._v(" 剩余测评次数："+_vm._s(_vm.test_num)+" ")]):_vm._e()]):_vm._e(),_c('div',{staticClass:"test click",class:{
                'back-gray': !_vm.myTest && _vm.test.user_eval_status == 2,
                'back-green': _vm.myTest && _vm.status == 1,
            },on:{"click":function($event){!_vm.myTest && _vm.test.user_eval_status == 2
                    ? ''
                    : _vm.goTest(_vm.test_num)}}},[_vm._v(" "+_vm._s(_vm.myTest ? _vm.status == 2 ? "重新测评 >>" : "继续测评 >>" : _vm.test.user_eval_status == 2 ? "已完成" : "去测评 >>")+" ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }