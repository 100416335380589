<template>
    <div class="Evaluation">
        <el-carousel class="banner" v-if="ExamList.length">
            <el-carousel-item
                v-for="(item, index) in ExamList"
                :key="'item' + index"
            >
                <img
                    :src="item.imgurl"
                    class="banner-img click"
                    @click="toQuestionnaire(item.id)"
                    alt=""
                />
            </el-carousel-item>
        </el-carousel>
        <div class="tabs" :class="{ 'mt-26': !ExamList.length }">
            <div class="tabs-card click mr-51" @click="switchActive(0)">
                <div class="text" :class="{ 'sel-text': active == 0 }">
                    测评中心
                </div>
                <div
                    class="subscript"
                    :class="{ 'sel-subscript': active == 0 }"
                ></div>
            </div>
            <div class="tabs-card click ml-51" @click="switchActive(1)">
                <div class="text" :class="{ 'sel-text': active == 1 }">
                    我的测评
                </div>
                <div
                    class="subscript"
                    :class="{ 'sel-subscript': active == 1 }"
                ></div>
            </div>
        </div>
        <!-- 列表 -->
        <div class="flex-jsb wrap">
            <EvaluationCard
                :myTest="active"
                v-for="(item, index) in testList"
                :test="active ? item.evaluation : item"
                :score="item.score"
                :status="item.status"
                :test_num="item.test_num || 0"
                :key="'Evaluation' + index"
            ></EvaluationCard>
            <NoData v-if="!testList.length" text="暂无测评"></NoData>
        </div>
        <div class="flex-jsc mt-23 mb-63">
            <Pagination
                :pageSize="8"
                :page="page"
                :total="total"
                @getPage="getPage"
            ></Pagination>
        </div>
    </div>
</template>

<script>
    import NoData from "@/components/NoData.vue";
    import EvaluationCard from "@/components/EvaluationCard.vue";
    import Pagination from "@/components/Pagination.vue";
    export default {
        name: "Evaluation",
        components: {
            EvaluationCard,
            Pagination,
            NoData,
        },
        data() {
            return {
                active: 0, //0 测评中心 1 我的测评
                total: 0, //总数据数
                testList: [], //测评列表
                ExamList: [], //轮播列表
                page: 1,
            };
        },

        watch: {
            active: function () {
                if (this.active) {
                    this.getMyTest();
                } else {
                    this.getTest();
                }
            },
        },

        mounted() {
            window.localStorage.setItem("navActive", 3);
            this.$store.commit("switchNav", 3);
            this.getTest();
            this.getExam();
        },
        methods: {
            switchActive(e) {
                this.active = e;
                this.page = 1;
            },
            getPage(e) {
                if (this.active) {
                    this.getMyTest(e);
                } else {
                    this.getTest(e);
                }
                this.page = e;
            },
            //获取问卷轮播图
            getExam() {
                this.$https
                    .get("/api/exam/evaluation/list", { kind: 1, limit: 999 })
                    .then((res) => {
                        this.ExamList = res.data.list;
                    });
            },
            //获取测试列表
            getTest(page = 1) {
                this.$https
                    .get("/api/exam/evaluation/list", { page, limit: 8, kind: 0 })
                    .then((res) => {
                        this.total = res.data.total_count;
                        this.testList = res.data.list;
                    });
            },
            //获取我的测评
            getMyTest(page = 1) {
                this.$https
                    .get("/api/exam/evaluation/user/list", { page, limit: 8 })
                    .then((res) => {
                        this.total = res.data.total_count;
                        this.testList = res.data.list;
                    });
            },
            //问卷调查
            toQuestionnaire(e) {
                this.$router.push({ path: "/questionnaire", query: { id: e } });
            },
        },
    };
</script>

<style lang='less' scoped>
.Evaluation {
    width: 1200px;
    margin: auto;
    .banner {
        width: 1050px;
        height: 450px;
        margin: 28px auto;
        /deep/.el-carousel__container {
            height: 100%;
            .banner-img {
                width: 100%;
                height: 100%;
            }
        }
    }
    .tabs {
        display: flex;
        width: 100%;
        justify-content: center;
        margin-bottom: 40px;
        .tabs-card {
            display: flex;
            flex-direction: column;
            align-items: center;
            .text {
                font-size: 22px;
                font-weight: 400;
                color: #000;
            }
            .sel-text {
                font-weight: 600;
                color: #e41414;
            }
            .subscript {
                width: 43px;
                height: 4px;
                border-radius: 4px;
                margin-top: 8px;
            }
            .sel-subscript {
                background: #e41414;
            }
        }
    }
}
</style>